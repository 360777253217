import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/video',
    name: 'useVideo',
    component: () => import(/* webpackChunkName: "about" */ '../views/useVideo.vue')
  },
	{
	  path: '/service',
	  name: 'aftService',
	  component: () => import(/* webpackChunkName: "about" */ '../views/aftService.vue')
	},
	{
	  path: '/guide',
	  name: 'delGuide',
	  component: () => import(/* webpackChunkName: "about" */ '../views/delGuide.vue')
	},
	{
	  path: '/question',
	  name: 'normalQus',
	  component: () => import(/* webpackChunkName: "about" */ '../views/normalQus.vue')
	}
]

const router = new VueRouter({
  routes
})

export default router
