import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import wx from 'weixin-js-sdk';
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import axios from "@/util/axios.js"

Vue.use(VideoPlayer)

Vue.config.productionTip = false
Vue.prototype.$axios = axios;



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
