import axios from 'axios';

import qs from 'qs';


const instance = axios.create({
	method: 'post',
	// withCredentials: true,     //跨域
	baseURL: 'https://home.maniya-tech.com/api/', //配置url
	transformRequest: [function(data, headers) {
		// loading = weui.loading('加载中...');
		return qs.stringify(data); //post请求处理数据格式
	}],
	transformResponse: [function(data) {
		// loading.hide();
		// console.log(data)
		return JSON.parse(data);
	}],
})


instance.interceptors.request.use(function(config) {
	config.url = config.url + '?time=' + Date.now()
	return config;
}, function(error) {
	// Do something with request error
	return Promise.reject(error);
});


export default instance;