<template>
  <div id="app">
    <router-view/>
  </div>
</template>


<script>
	import sdk from './router/share.js';
	export default {
		name: 'App',
		mounted(){
			let that = this;
			const ua = window.navigator.userAgent.toLowerCase();
			let isIOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
			let url = ''
			if(isIOS){
				 url = encodeURIComponent(window.location.href.split('#')[0]);
			}else{
				 url = window.location.href.split('#')[0];
			}
			
			console.log(url)
			const dataForWeixin = {
				title: '跑跑太空熊代理商指南',
				desc: '使用视频，交付指南，常见问题，售后服务',
				linkurl: url,
				img: 'https://maniya-1303875517.cos.ap-guangzhou.myqcloud.com/images/002e6ea68493a0908dbbb30b6fbba5a.jpg',
			};
			
			if(ua.match(/MicroMessenger/i) == 'micromessenger'){
				sdk.getJSSDK(that,url,dataForWeixin)
			}else{
				console.log('不是微信浏览器')
			}
		},
		methods:{
			
		}
	}
</script>


<style>

*{
	margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
	max-width: 768px;
	margin: 0 auto;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
