<template>
  <div class="home">
		<div class="banner">
			<Swiper :showIndicator="false">
			  <Slide>
					<img src="../assets/1.jpg" class="banner_img"/>
			  </Slide>
				<!-- <Slide>
					<img src="../assets/E55728.png" class="banner_img"/>
				</Slide>
				<Slide>
					<img src="../assets/F5BF42.png" class="banner_img"/>
				</Slide>
				<Slide>
					<img src="../assets/F0584D.png" class="banner_img"/>
				</Slide> -->
			</Swiper>
		</div>
    <div class="title">
			<h1>代理商手册</h1>
		</div>
		<div class="entrance">
			<div class="flex">
				<div class="item" @click="useVideo">
					<img src="../assets/2.jpg" class="img" />
					<div class="font">使用及维修视频</div>
				</div>
				<div class="item" @click="delGuide">
					<img src="../assets/3.jpg" class="img" />
					<div class="font">交付指南</div>
				</div>
			</div>
			<div class="flex mt">
				<div class="item" @click="normalQus">
					<img src="../assets/4.jpg" class="img" />
					<div class="font">常见问题</div>
				</div>
				<div class="item" @click="aftService">
					<img src="../assets/5.jpg" class="img" />
					<div class="font">售后服务</div>
				</div>
			</div>
		</div>
		<div class="logo">
			<img src="../assets/mny.png" class="logo_img" />
		</div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Swiper, Slide } from 'vue-swiper-component';
export default {
  name: 'Home',
  components: {
    Swiper,
    Slide
  },
	data(){
		return {
			
		}
	},
	methods:{
		useVideo(){
			this.$router.push('/video')
		},
		delGuide(){
			this.$router.push('/guide')
		},
		normalQus(){
			this.$router.push('/question')
		},
		aftService(){
			this.$router.push('/service')
		}
	}
}
</script>

<style scoped="scoped">
	.home{
		margin: 0 auto;
	}
	.banner{
		width: 100%;
		/* height: 2rem; */
		
	}
	.banner_img{
		width: 100%;
		/* height: 2rem; */
	}
	.wh_indicator {
		bottom: -10px;
	}
	.title{
		width: 100%;
		margin-top: 0.2rem;
	}
	.title h1{
		font-size: 0.24rem;
		font-weight: bold;
		color: #222326;
	}
	.entrance{
		margin-top: 0.2rem;
		width: 100%;
	}
	.flex{
		width: 88%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.item{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 48%;
	}
	.img{
		/* width: 1.5rem;
		height: 0.8rem; */
		width: 100%;
	}
	.font{
		font-size: 0.16rem;
	}
	.mt{
		margin-top: 0.2rem;
	}
	.logo{
		width: 45%;
		margin: 0.2rem auto;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
	.logo_img{
		width: 100%;
	}
</style>
